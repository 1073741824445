
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function SearchPageRT () {
    return _createElement('div', { 'className': 'clear-self cm_SearchPage' }, _createElement('div', { 'className': 'cm_results-header' }, [this.FilterChips(function () {
            return _createElement('div', { 'className': 'cm_FilterChips' }, [this.filterChips(function () {
                    function repeatChips1(chips, chipsIndex) {
                        return [chips(function () {
                                function repeatI1_reviewChip(i, iIndex) {
                                    return _createElement('span', {
                                        'key': `${ this.field }|${ i }`,
                                        'className': 'cm_star cm_star__' + (!(this.value - i) || this.value - i < 0.25 ? 'empty' : this.value - i < 0.75 ? 'half' : 'full')
                                    });
                                }
                                function scopeInchMm1_rangeChip() {
                                    var inch = [].includes(this.field) ? '"' : '';
                                    var mm = [
                                        'wheel_bore',
                                        'wheel_offset'
                                    ].includes(this.field) ? 'mm' : '';
                                    return _createElement('div', { 'className': 'facetdiv cmTemplate_rangeChip' }, _createElement('a', {
                                        'className': 'facetentrykey',
                                        'tabIndex': '0',
                                        'role': 'listitem'
                                    }, _createElement('span', { 'className': 'cm_chip-name' }, this.name, ':'), this.finite ? _createElement(_Fragment, { 'key': '365' }, this.selectedRange[0], inch, mm, ' to ', this.selectedRange[1], inch, mm) : null, this.toPosInf ? _createElement(_Fragment, { 'key': '491' }, this.selectedRange[0], inch, mm, this.field !== 'Horsepower' ? ['\n      and more'] : null) : null, this.toNegInf ? _createElement(_Fragment, { 'key': '671' }, this.selectedRange[1], inch, mm, ' and less') : null), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                            'width': '10',
                                            'height': '10',
                                            'viewBox': '0 0 14 15',
                                            'fill': 'none',
                                            'xmlns': 'http://www.w3.org/2000/svg',
                                            'className': 'icon icon-close',
                                            'key': '8010'
                                        }, _createElement('path', {
                                            'd': 'M14 1.556L12.944 0.5L7 6.444L1.056 0.5L0 1.556L5.944 7.5L0 13.444L1.056 14.5L7 8.556L12.944 14.5L14 13.444L8.057 7.5L14 1.556Z',
                                            'fill': 'currentColor'
                                        }))]));
                                }
                                return this.template === 'chip' ? _createElement('div', { 'className': 'facetdiv cmTemplate_chip' }, _createElement('a', {
                                    'className': 'facetentrykey',
                                    'tabIndex': '0',
                                    'role': 'listitem'
                                }, _createElement('span', { 'className': 'cm_chip-name' }, this.name === 'filterQuery' ? 'search within' : this.name, ':'), '\n  ', this.value, '\n'), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                        'width': '10',
                                        'height': '10',
                                        'viewBox': '0 0 14 15',
                                        'fill': 'none',
                                        'xmlns': 'http://www.w3.org/2000/svg',
                                        'className': 'icon icon-close',
                                        'key': '2570'
                                    }, _createElement('path', {
                                        'd': 'M14 1.556L12.944 0.5L7 6.444L1.056 0.5L0 1.556L5.944 7.5L0 13.444L1.056 14.5L7 8.556L12.944 14.5L14 13.444L8.057 7.5L14 1.556Z',
                                        'fill': 'currentColor'
                                    }))])) : this.template === 'reviewChip' ? _createElement('div', { 'className': 'facetdiv cmTemplate_reviewChip' }, _createElement.apply(this, [
                                    'a',
                                    {
                                        'className': 'facetentrykey',
                                        'tabIndex': '0',
                                        'role': 'listitem'
                                    },
                                    _createElement('span', { 'className': 'cm_chip-name' }, this.name, ': '),
                                    _map([
                                        0,
                                        1,
                                        2,
                                        3,
                                        4
                                    ], repeatI1_reviewChip.bind(this)),
                                    this.value !== '5' ? _createElement('span', { 'key': '391' }, ' and up') : null
                                ]), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                        'width': '10',
                                        'height': '10',
                                        'viewBox': '0 0 14 15',
                                        'fill': 'none',
                                        'xmlns': 'http://www.w3.org/2000/svg',
                                        'className': 'icon icon-close',
                                        'key': '4900'
                                    }, _createElement('path', {
                                        'd': 'M14 1.556L12.944 0.5L7 6.444L1.056 0.5L0 1.556L5.944 7.5L0 13.444L1.056 14.5L7 8.556L12.944 14.5L14 13.444L8.057 7.5L14 1.556Z',
                                        'fill': 'currentColor'
                                    }))])) : this.template === 'rangeChip' ? scopeInchMm1_rangeChip.apply(this, []) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                            }, { count: undefined })];
                    }
                    return _createElement('div', { 'className': 'cm_filterChips' }, _createElement('div', { 'className': 'facetholder' }, _createElement('div', { 'className': 'facetbody' }, _createElement('div', {
                        'className': 'cm_btn button button--secondary cm_filter-chips_button',
                        'data-cm-role': 'start-over'
                    }, '\n        All Products\n        ', _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                            'width': '10',
                            'height': '10',
                            'viewBox': '0 0 14 15',
                            'fill': 'none',
                            'xmlns': 'http://www.w3.org/2000/svg',
                            'className': 'icon icon-close',
                            'key': '2420'
                        }, _createElement('path', {
                            'd': 'M14 1.556L12.944 0.5L7 6.444L1.056 0.5L0 1.556L5.944 7.5L0 13.444L1.056 14.5L7 8.556L12.944 14.5L14 13.444L8.057 7.5L14 1.556Z',
                            'fill': 'currentColor'
                        }))])), _createElement('span', { 'className': 'delimeter' }), _createElement.apply(this, [
                        'div',
                        {
                            'role': 'list',
                            'className': 'cmRepeater_chips'
                        },
                        _map(this.chips, repeatChips1.bind(this))
                    ]))));
                }, {
                    widgetName: 'filter-chips',
                    items: undefined
                })]);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.SearchHeader(function () {
            return _createElement('div', { 'className': 'cm_SearchHeader__top cm_SearchHeader' }, _createElement('div', { 'className': 'cm_total-hits' }, _createElement('span', {}, this.totalHits, ' results')), [this.facetToggle(function () {
                    return _createElement('div', { 'className': 'cm_facetToggle' }, _createElement('span', {
                        'className': 'cm_facet-toggle_label',
                        'onClick': this.toggleFacet
                    }, '\n  Show Universal Parts\n'), _createElement('label', { 'className': 'cm_facet-toggle_switch' }, _createElement('input', {
                        'className': 'cm_facet-toggle_input',
                        'type': 'checkbox',
                        'checked': this.isToggled,
                        'onChange': this.toggleFacet
                    }), _createElement('span', { 'className': 'cm_facet-toggle_slider' })));
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })], _createElement('div', { 'className': 'cm_sort sort-by' }, _createElement('span', { 'className': 'sort-label' }, 'Sort by'), '\n  ', this.sortSelect, '\n'));
        }, {
            widgetName: 'search-header',
            items: undefined
        })], [this.CustomFacetDialogButton(function () {
            return _createElement('div', { 'className': 'cm_facetDialogButton  cm_CustomFacetDialogButton' }, _createElement('div', { 'className': 'cm_dialogButton  button button--secondary' }, [_createElement('svg', {
                    'width': '16',
                    'height': '12',
                    'viewBox': '0 0 16 12',
                    'fill': 'none',
                    'xmlns': 'http://www.w3.org/2000/svg',
                    'key': '1330'
                }, _createElement('g', { 'clipPath': 'url(#clip0_3_7531)' }, _createElement('path', {
                    'd': 'M14.5 2H1.5V4H14.5V2Z',
                    'fill': '#1D1D1D'
                }), _createElement('path', {
                    'd': 'M14.5 8H1.5V10H14.5V8Z',
                    'fill': '#1D1D1D'
                }), _createElement('path', {
                    'd': 'M12.5 6C14.1569 6 15.5 4.65685 15.5 3C15.5 1.34315 14.1569 0 12.5 0C10.8431 0 9.5 1.34315 9.5 3C9.5 4.65685 10.8431 6 12.5 6Z',
                    'fill': 'white'
                }), _createElement('path', {
                    'd': 'M12.5 5C13.6046 5 14.5 4.10457 14.5 3C14.5 1.89543 13.6046 1 12.5 1C11.3954 1 10.5 1.89543 10.5 3C10.5 4.10457 11.3954 5 12.5 5Z',
                    'stroke': '#1D1D1D',
                    'strokeWidth': '2'
                }), _createElement('path', {
                    'd': 'M3.5 12C5.15685 12 6.5 10.6569 6.5 9C6.5 7.34315 5.15685 6 3.5 6C1.84315 6 0.5 7.34315 0.5 9C0.5 10.6569 1.84315 12 3.5 12Z',
                    'fill': 'white'
                }), _createElement('path', {
                    'd': 'M3.5 11C4.60457 11 5.5 10.1046 5.5 9C5.5 7.89543 4.60457 7 3.5 7C2.39543 7 1.5 7.89543 1.5 9C1.5 10.1046 2.39543 11 3.5 11Z',
                    'stroke': '#1D1D1D',
                    'strokeWidth': '2'
                })), _createElement('defs', {}, _createElement('clipPath', { 'id': 'clip0_3_7531' }, _createElement('rect', {
                    'width': '15',
                    'height': '12',
                    'fill': 'white',
                    'transform': 'translate(0.5)'
                }))))], ' Filter By\n      '));
        }, {
            widgetName: 'undefined',
            items: undefined
        })]), this.responseState !== 'nothing' ? _createElement('div', {
        'className': 'cm_FacetPanel',
        'key': '11168'
    }, [this.PumpSearchFacetPanel(function () {
            function repeatSelects1(selects, selectsIndex) {
                return [selects(function () {
                        function repeatEntry1(popularEntries, unpopularEntries, isPopularField, entry, entryIndex) {
                            return _createElement('option', {
                                'disabled': this.loading,
                                'value': entry.term,
                                'key': entry.value
                            }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                        }
                        function repeatEntry2(popularEntries, unpopularEntries, isPopularField, entry, entryIndex) {
                            return _createElement('option', {
                                'disabled': this.loading,
                                'value': entry.term,
                                'key': entry.value
                            }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                        }
                        function scopePopularEntriesUnpopularEntriesIsPopularField3() {
                            var popularEntries = this.entries.filter(entry => entry.payload === 'Popular');
                            var unpopularEntries = this.entries.filter(entry => entry.payload !== 'Popular');
                            var isPopularField = popularEntries.length && unpopularEntries.length;
                            return [
                                !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                    'value': '',
                                    'key': 'null-option'
                                }, '\n          Select ', this.title, '\n        ') : null,
                                this.loading ? _createElement('option', {
                                    'key': 'loading-option',
                                    'disabled': true
                                }, '...loading...') : null,
                                isPopularField ? [
                                    _createElement('option', {
                                        'className': 'cm_option_title',
                                        'disabled': true,
                                        'key': '10611'
                                    }, 'Popular ', this.title === 'Make' ? 'Makes' : this.title === 'Model' ? 'Models' : 'Values'),
                                    _map(popularEntries, repeatEntry1.bind(this, popularEntries, unpopularEntries, isPopularField)),
                                    _createElement('option', {
                                        'className': 'cm_option_title',
                                        'disabled': true,
                                        'key': '10614'
                                    }, 'Additional ', this.title === 'Make' ? 'Makes' : this.title === 'Model' ? 'Models' : 'Values')
                                ] : null,
                                _map(isPopularField ? unpopularEntries : this.entries, repeatEntry2.bind(this, popularEntries, unpopularEntries, isPopularField))
                            ];
                        }
                        function scopeShowSelectedValues4() {
                            var showSelectedValues = this.selectedEntries.length;
                            return _createElement('option', {
                                'key': '_current',
                                'value': '_current'
                            }, showSelectedValues ? [this.selectedEntries.map(e => e.value).join(', ')] : null, !showSelectedValues ? [
                                'Select ',
                                this.title
                            ] : null);
                        }
                        function repeatEntry5(entry, index) {
                            return _createElement('div', {
                                'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                                'key': entry.value,
                                'onClick': () => this.onChange(entry.term)
                            }, this.showCheckboxes ? _createElement('input', {
                                'type': 'checkbox',
                                'readOnly': true,
                                'checked': entry.selected,
                                'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                                'key': '3399'
                            }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                                'className': 'cm_dropdown_hitCount',
                                'key': '3601'
                            }, entry.hitCount) : null);
                        }
                        return _createElement('div', { 'className': this.extraClassName }, !this.isSearchable ? _createElement('div', {
                            'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                            'key': '39'
                        }, _createElement('select', {
                            'className': 'cm_select_inner-select cm_select__pretty',
                            'onChange': this.onChange,
                            'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                            'disabled': this.disabled,
                            'aria-label': this.title
                        }, this.useNativeDropdown ? scopePopularEntriesUnpopularEntriesIsPopularField3.apply(this, []) : null, !this.useNativeDropdown ? scopeShowSelectedValues4.apply(this, []) : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                                    return _createElement('div', { 'className': 'cm_filterInput' });
                                }, {
                                    widgetName: 'undefined',
                                    items: undefined
                                })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                            'div',
                            {
                                'className': 'cm_dropdown cm_select_dropdown cm_hide',
                                'aria-label': this.title,
                                'key': '2702'
                            },
                            !this.hideNullOption ? _createElement('div', {
                                'className': 'option',
                                'key': 'null-option',
                                'onClick': () => this.onChange('')
                            }, '\n      Select ', this.title, '\n    ') : null,
                            this.loading ? _createElement('div', {
                                'className': 'option',
                                'key': 'null-option',
                                'disabled': true
                            }, '...loading...') : null,
                            !this.loading ? _map(this.entries, repeatEntry5.bind(this)) : null
                        ]) : null);
                    }, { count: undefined })];
            }
            function repeatInputs2(inputs, inputsIndex) {
                return [inputs(function () {
                        return _createElement('div', {}, [this.facetInput(function () {
                                return _createElement('div', { 'className': 'cm_facetInput' });
                            }, {
                                widgetName: 'undefined',
                                items: undefined
                            })]);
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'cm_PumpSearchFacetPanel' }, _createElement('div', { 'className': 'cm_req-panel_pump-facet-container' }, _createElement('div', { 'className': 'cm_req-panel_pump-facet' }, _createElement('div', { 'className': 'facettitle' }, _createElement('span', {}, 'PUMP FINDER')), [_createElement('div', {
                    'className': 'cm_req-panel_pump_inner',
                    'key': '2000'
                }, _createElement.apply(this, [
                    'div',
                    { 'className': 'cm_req-panel_pump_select-container  cmRepeater_selects' },
                    _map(this.selects, repeatSelects1.bind(this))
                ]), _createElement.apply(this, [
                    'div',
                    { 'className': 'cm_req-panel_pump_input cmRepeater_inputs' },
                    _map(this.inputs, repeatInputs2.bind(this))
                ]), _createElement('div', { 'className': 'cm_req-panel_pump_button-container' }, _createElement('button', {
                    'onClick': this.clear,
                    'className': 'cm_req-panel_button__clear',
                    'disabled': this.disableClear
                }, [_createElement('svg', {
                        'width': '24',
                        'height': '24',
                        'viewBox': '0 0 24 24',
                        'fill': 'none',
                        'xmlns': 'http://www.w3.org/2000/svg',
                        'key': '67360'
                    }, _createElement('g', { 'id': 'restart_alt' }, _createElement('mask', {
                        'id': 'mask0_1239_10799',
                        'style': { maskType: 'alpha' },
                        'maskUnits': 'userSpaceOnUse',
                        'x': '0',
                        'y': '0',
                        'width': '24',
                        'height': '24'
                    }, _createElement('rect', {
                        'id': 'Bounding box',
                        'width': '24',
                        'height': '24',
                        'fill': '#D9D9D9'
                    })), _createElement('g', { 'mask': 'url(#mask0_1239_10799)' }, _createElement('path', {
                        'id': 'restart_alt_2',
                        'd': 'M11 20.4307C9.12434 20.1871 7.57211 19.3609 6.34327 17.9519C5.11442 16.5429 4.5 14.8923 4.5 13C4.5 11.9833 4.70064 11.0093 5.10192 10.0779C5.50321 9.14649 6.06538 8.32822 6.78845 7.62309L7.85765 8.69232C7.24355 9.25257 6.78042 9.90288 6.46825 10.6433C6.15607 11.3836 5.99997 12.1692 5.99997 13C5.99997 14.4667 6.46824 15.7593 7.40477 16.8779C8.34132 17.9965 9.53972 18.6808 11 18.9308V20.4307ZM13 20.45V18.95C14.4436 18.6577 15.6378 17.958 16.5827 16.8509C17.5275 15.7439 18 14.4602 18 13C18 11.3333 17.4166 9.91666 16.25 8.74999C15.0833 7.58332 13.6666 6.99999 12 6.99999H11.6461L12.9961 8.35002L11.9423 9.40384L8.78848 6.25002L11.9423 3.09619L12.9961 4.15002L11.6461 5.50002H12C14.0923 5.50002 15.8653 6.22694 17.3192 7.68079C18.773 9.13462 19.5 10.9077 19.5 13C19.5 14.882 18.8839 16.5243 17.6519 17.9269C16.4198 19.3294 14.8692 20.1705 13 20.45Z',
                        'fill': 'white'
                    }))))], '\n      Reset\n    '), _createElement('button', {
                    'onClick': this.submit,
                    'className': 'button button--primary  button cm_req-panel_button__go',
                    'disabled': !this.hasRequiredSelection
                }, '\n      find my pump\n      ', _createElement('i', {
                    'aria-hidden': 'true',
                    'className': 'fa fa-angle-right'
                }))))])));
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.SearchVehicleWidget(function () {
            function repeatSelects1(selects, selectsIndex) {
                return [selects(function () {
                        function repeatEntry1(popularEntries, unpopularEntries, isPopularField, entry, entryIndex) {
                            return _createElement('option', {
                                'disabled': this.loading,
                                'value': entry.term,
                                'key': entry.value
                            }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                        }
                        function repeatEntry2(popularEntries, unpopularEntries, isPopularField, entry, entryIndex) {
                            return _createElement('option', {
                                'disabled': this.loading,
                                'value': entry.term,
                                'key': entry.value
                            }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                        }
                        function scopePopularEntriesUnpopularEntriesIsPopularField3() {
                            var popularEntries = this.entries.filter(entry => entry.payload === 'Popular');
                            var unpopularEntries = this.entries.filter(entry => entry.payload !== 'Popular');
                            var isPopularField = popularEntries.length && unpopularEntries.length;
                            return [
                                !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                    'value': '',
                                    'key': 'null-option'
                                }, '\n          Select ', this.title, '\n        ') : null,
                                this.loading ? _createElement('option', {
                                    'key': 'loading-option',
                                    'disabled': true
                                }, '...loading...') : null,
                                isPopularField ? [
                                    _createElement('option', {
                                        'className': 'cm_option_title',
                                        'disabled': true,
                                        'key': '10611'
                                    }, 'Popular ', this.title === 'Make' ? 'Makes' : this.title === 'Model' ? 'Models' : 'Values'),
                                    _map(popularEntries, repeatEntry1.bind(this, popularEntries, unpopularEntries, isPopularField)),
                                    _createElement('option', {
                                        'className': 'cm_option_title',
                                        'disabled': true,
                                        'key': '10614'
                                    }, 'Additional ', this.title === 'Make' ? 'Makes' : this.title === 'Model' ? 'Models' : 'Values')
                                ] : null,
                                _map(isPopularField ? unpopularEntries : this.entries, repeatEntry2.bind(this, popularEntries, unpopularEntries, isPopularField))
                            ];
                        }
                        function scopeShowSelectedValues4() {
                            var showSelectedValues = this.selectedEntries.length;
                            return _createElement('option', {
                                'key': '_current',
                                'value': '_current'
                            }, showSelectedValues ? [this.selectedEntries.map(e => e.value).join(', ')] : null, !showSelectedValues ? [
                                'Select ',
                                this.title
                            ] : null);
                        }
                        function repeatEntry5(entry, index) {
                            return _createElement('div', {
                                'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                                'key': entry.value,
                                'onClick': () => this.onChange(entry.term)
                            }, this.showCheckboxes ? _createElement('input', {
                                'type': 'checkbox',
                                'readOnly': true,
                                'checked': entry.selected,
                                'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                                'key': '3399'
                            }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                                'className': 'cm_dropdown_hitCount',
                                'key': '3601'
                            }, entry.hitCount) : null);
                        }
                        return _createElement('div', { 'className': this.extraClassName }, !this.isSearchable ? _createElement('div', {
                            'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                            'key': '39'
                        }, _createElement('select', {
                            'className': 'cm_select_inner-select cm_select__pretty',
                            'onChange': this.onChange,
                            'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                            'disabled': this.disabled,
                            'aria-label': this.title
                        }, this.useNativeDropdown ? scopePopularEntriesUnpopularEntriesIsPopularField3.apply(this, []) : null, !this.useNativeDropdown ? scopeShowSelectedValues4.apply(this, []) : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                                    return _createElement('div', { 'className': 'cm_filterInput' });
                                }, {
                                    widgetName: 'undefined',
                                    items: undefined
                                })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                            'div',
                            {
                                'className': 'cm_dropdown cm_select_dropdown cm_hide',
                                'aria-label': this.title,
                                'key': '2702'
                            },
                            !this.hideNullOption ? _createElement('div', {
                                'className': 'option',
                                'key': 'null-option',
                                'onClick': () => this.onChange('')
                            }, '\n      Select ', this.title, '\n    ') : null,
                            this.loading ? _createElement('div', {
                                'className': 'option',
                                'key': 'null-option',
                                'disabled': true
                            }, '...loading...') : null,
                            !this.loading ? _map(this.entries, repeatEntry5.bind(this)) : null
                        ]) : null);
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__search facetholder cm_SearchVehicleWidget' }, this.template === 'active' ? _createElement('div', {
                'className': 'cm_vehicle-widget_dropdowns cmTemplate_active',
                'key': '92'
            }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, window.Convermax.config?.selectYourVehicleTitle || 'Select Your Vehicle'), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
                'div',
                { 'className': 'cm_vehicle-widget_select-container  cmRepeater_selects' },
                _map(this.selects, repeatSelects1.bind(this))
            ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
                'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__go button button--primary',
                'onClick': this.selectVehicle,
                'disabled': !this.allowToSetVehicle,
                'role': 'button',
                'tabIndex': this.allowToSetVehicle ? '0' : '-1'
            }, '\n    ', window.document.body.classList.contains('template-product') ? 'Verify fitment' : 'GO', '\n  '), _createElement('div', {
                'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__clear button button--secondary',
                'onClick': this.discardVehicle,
                'disabled': !this.allowToDiscardVehicle,
                'role': 'button',
                'tabIndex': this.allowToDiscardVehicle ? '0' : '-1'
            }, [_createElement('svg', {
                    'width': '24',
                    'height': '24',
                    'viewBox': '0 0 24 24',
                    'fill': 'none',
                    'xmlns': 'http://www.w3.org/2000/svg',
                    'key': '69810'
                }, _createElement('g', { 'id': 'restart_alt' }, _createElement('mask', {
                    'id': 'mask0_1239_10799',
                    'style': { maskType: 'alpha' },
                    'maskUnits': 'userSpaceOnUse',
                    'x': '0',
                    'y': '0',
                    'width': '24',
                    'height': '24'
                }, _createElement('rect', {
                    'id': 'Bounding box',
                    'width': '24',
                    'height': '24',
                    'fill': '#D9D9D9'
                })), _createElement('g', { 'mask': 'url(#mask0_1239_10799)' }, _createElement('path', {
                    'id': 'restart_alt_2',
                    'd': 'M11 20.4307C9.12434 20.1871 7.57211 19.3609 6.34327 17.9519C5.11442 16.5429 4.5 14.8923 4.5 13C4.5 11.9833 4.70064 11.0093 5.10192 10.0779C5.50321 9.14649 6.06538 8.32822 6.78845 7.62309L7.85765 8.69232C7.24355 9.25257 6.78042 9.90288 6.46825 10.6433C6.15607 11.3836 5.99997 12.1692 5.99997 13C5.99997 14.4667 6.46824 15.7593 7.40477 16.8779C8.34132 17.9965 9.53972 18.6808 11 18.9308V20.4307ZM13 20.45V18.95C14.4436 18.6577 15.6378 17.958 16.5827 16.8509C17.5275 15.7439 18 14.4602 18 13C18 11.3333 17.4166 9.91666 16.25 8.74999C15.0833 7.58332 13.6666 6.99999 12 6.99999H11.6461L12.9961 8.35002L11.9423 9.40384L8.78848 6.25002L11.9423 3.09619L12.9961 4.15002L11.6461 5.50002H12C14.0923 5.50002 15.8653 6.22694 17.3192 7.68079C18.773 9.13462 19.5 10.9077 19.5 13C19.5 14.882 18.8839 16.5243 17.6519 17.9269C16.4198 19.3294 14.8692 20.1705 13 20.45Z',
                    'fill': 'white'
                }))))], _createElement('span', {}, 'Reset'))))) : null, this.template === 'locked' ? _createElement('div', {
                'className': 'cm_vehicle-widget_label cmTemplate_locked',
                'key': '8338'
            }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, '\n  ', this.vehicleString, '\n  ', _createElement('span', { 'className': 'cm_vehicle-widget_subtitle' }, this.fitmentSearchTitle), [this.facetToggle(function () {
                    return _createElement('div', { 'className': 'cm_facetToggle' }, _createElement('span', {
                        'className': 'cm_facet-toggle_label',
                        'onClick': this.toggleFacet
                    }, '\n  Show Universal Parts\n'), _createElement('label', { 'className': 'cm_facet-toggle_switch' }, _createElement('input', {
                        'className': 'cm_facet-toggle_input',
                        'type': 'checkbox',
                        'checked': this.isToggled,
                        'onChange': this.toggleFacet
                    }), _createElement('span', { 'className': 'cm_facet-toggle_slider' })));
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })]), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
                'className': 'cm_vehicle-widget_button button button--primary',
                'onClick': this.changeVehicle,
                'role': 'button',
                'tabIndex': '0'
            }, '\n      Change Vehicle\n    '), _createElement('div', {
                'className': 'cm_vehicle-widget_button button button--secondary',
                'onClick': this.discardVehicle,
                'role': 'button',
                'tabIndex': '0'
            }, '\n      Clear Selection\n    ')))) : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.Facets(function () {
            return _createElement('div', { 'className': 'cm_Facets' }, [this.facets(function () {
                    function repeatFacets1(facets, facetsIndex) {
                        return [facets(function () {
                                function repeatShowAlwaysValues1(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                                    return [ShowAlwaysValues(function () {
                                            return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                'type': 'checkbox',
                                                'className': 'cm_facet_checkbox',
                                                'readOnly': true,
                                                'checked': this.isSelected
                                            }), _createElement('a', {
                                                'className': 'facetentrykey',
                                                'tabIndex': '0',
                                                'role': 'listitem'
                                            }, this.value), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                        }, { count: undefined })];
                                }
                                function repeatValues2(Values, ValuesIndex) {
                                    return [Values(function () {
                                            return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                'type': 'checkbox',
                                                'className': 'cm_facet_checkbox',
                                                'readOnly': true,
                                                'checked': this.isSelected
                                            }), _createElement('a', {
                                                'className': 'facetentrykey',
                                                'tabIndex': '0',
                                                'role': 'listitem'
                                            }, this.value), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                        }, { count: undefined })];
                                }
                                function repeatValues3(Values, ValuesIndex) {
                                    return [Values(function () {
                                            return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                'type': 'checkbox',
                                                'className': 'cm_facet_checkbox',
                                                'readOnly': true,
                                                'checked': this.isSelected
                                            }), !this.isCustomRange ? [
                                                _createElement('a', {
                                                    'className': 'facetentrykey',
                                                    'tabIndex': '0',
                                                    'role': 'listitem',
                                                    'key': '1541'
                                                }, _createElement('span', {}, this.value)),
                                                _createElement('div', {
                                                    'className': 'facetentryval',
                                                    'key': '1543'
                                                }, this.hitCount)
                                            ] : null, this.isCustomRange ? [_createElement('a', {
                                                    'className': 'facetentrykey',
                                                    'tabIndex': '0',
                                                    'role': 'listitem',
                                                    'key': '3901'
                                                }, this.finite ? _createElement('span', { 'key': '503' }, this.range[0], ' - ', this.range[1]) : null, this.toPosInf ? _createElement('span', { 'key': '580' }, this.range[0], ' and more') : null, this.toNegInf ? _createElement('span', { 'key': '650' }, this.range[1], ' and less') : null)] : null);
                                        }, { count: undefined })];
                                }
                                function scopeInchMm4() {
                                    var inch = [].includes(this.field) ? '"' : '';
                                    var mm = [
                                        'wheel_bore',
                                        'wheel_offset'
                                    ].includes(this.field) ? 'mm' : '';
                                    return _createElement('div', {
                                        'className': 'cmTemplate_sliderFacet',
                                        'key': '13125'
                                    }, _createElement('div', { 'className': 'facetslider' }, _createElement('div', { 'className': 'facetsliderinfo' }, '\n    Selected: ', this.selectedRange[0], inch, mm, ' to ', this.selectedRange[1], inch, mm, '\n  '), '\n  ', this.slider, '\n  ', _createElement('div', { 'className': 'cm_slider-bounds' }, _createElement('div', { 'className': 'min' }, this.min, inch, mm), _createElement('div', { 'className': 'max' }, this.max, inch, mm), _createElement('div', { 'className': 'clear' }))), [this.Inputs(function () {
                                            return _createElement('div', { 'className': 'input cm_Inputs' }, this.inputMin ? [[this.inputMin(function () {
                                                        return _createElement('div', {
                                                            'placeholder': 'Min',
                                                            'className': 'cm_inputMin'
                                                        });
                                                    }, {
                                                        widgetName: 'undefined',
                                                        items: undefined
                                                    })]] : null, this.inputMin && this.inputMax ? _createElement('span', {
                                                'className': 'separator',
                                                'key': '334'
                                            }, '\u2014') : null, this.inputMax ? [[this.inputMax(function () {
                                                        return _createElement('div', {
                                                            'placeholder': 'Max',
                                                            'className': 'cm_inputMax'
                                                        });
                                                    }, {
                                                        widgetName: 'undefined',
                                                        items: undefined
                                                    })]] : null, _createElement('button', {
                                                'type': 'button',
                                                'className': 'cm_btn button button--secondary',
                                                'onClick': this.setCustomRange
                                            }, '\n    Apply Price Range\n  '));
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })]);
                                }
                                function repeatShowAlwaysValues5(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                                    return [ShowAlwaysValues(function () {
                                            function scopeStarFill1(i, iIndex) {
                                                var starFill = this.value - i;
                                                return _createElement('span', {
                                                    'key': i,
                                                    'className': 'cm_star cm_star__' + (starFill < 0.25 ? 'empty' : starFill < 0.75 ? 'half' : 'full')
                                                });
                                            }
                                            function repeatI2(i, iIndex, starFill) {
                                                return scopeStarFill1.apply(this, [
                                                    i,
                                                    iIndex
                                                ]);
                                            }
                                            return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                'type': 'checkbox',
                                                'className': 'cm_facet_checkbox',
                                                'readOnly': true,
                                                'checked': this.isSelected
                                            }), _createElement('a', {
                                                'className': 'facetentrykey',
                                                'tabIndex': '0',
                                                'role': 'listitem'
                                            }, _createElement.apply(this, [
                                                'span',
                                                { 'className': 'cm_review-stars' },
                                                _map([
                                                    0,
                                                    1,
                                                    2,
                                                    3,
                                                    4
                                                ], repeatI2.bind(this))
                                            ]), this.value !== '5' ? _createElement('span', {
                                                'className': 'cm_and-up',
                                                'key': '499'
                                            }, ' and up') : null), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                        }, { count: undefined })];
                                }
                                function repeatValues6(Values, ValuesIndex) {
                                    return [Values(function () {
                                            function scopeStarFill1(i, iIndex) {
                                                var starFill = this.value - i;
                                                return _createElement('span', {
                                                    'key': i,
                                                    'className': 'cm_star cm_star__' + (starFill < 0.25 ? 'empty' : starFill < 0.75 ? 'half' : 'full')
                                                });
                                            }
                                            function repeatI2(i, iIndex, starFill) {
                                                return scopeStarFill1.apply(this, [
                                                    i,
                                                    iIndex
                                                ]);
                                            }
                                            return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                'type': 'checkbox',
                                                'className': 'cm_facet_checkbox',
                                                'readOnly': true,
                                                'checked': this.isSelected
                                            }), _createElement('a', {
                                                'className': 'facetentrykey',
                                                'tabIndex': '0',
                                                'role': 'listitem'
                                            }, _createElement.apply(this, [
                                                'span',
                                                { 'className': 'cm_review-stars' },
                                                _map([
                                                    0,
                                                    1,
                                                    2,
                                                    3,
                                                    4
                                                ], repeatI2.bind(this))
                                            ]), this.value !== '5' ? _createElement('span', {
                                                'className': 'cm_and-up',
                                                'key': '499'
                                            }, ' and up') : null), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                        }, { count: undefined })];
                                }
                                return _createElement('div', { 'className': 'facetholder cm_field-' + this.field }, this.template !== 'toggleFacet' ? [
                                    _createElement('div', {
                                        'className': 'facettitle',
                                        'data-cm-role': 'toggle-facet',
                                        'tabIndex': '0',
                                        'key': '551'
                                    }, _createElement('span', {}, this.name), this.isCollapsed ? [_createElement('svg', {
                                            'width': '27',
                                            'height': '27',
                                            'viewBox': '0 0 12 28',
                                            'fill': 'none',
                                            'xmlns': 'http://www.w3.org/2000/svg',
                                            'key': '2360'
                                        }, _createElement('path', {
                                            'fillRule': 'evenodd',
                                            'clipRule': 'evenodd',
                                            'd': 'M18.9256 15.4749L18.0001 16.4004L13.2273 11.6276L8.45456 16.4004L7.52904 15.4749L13.2273 9.77659L18.9256 15.4749Z',
                                            'fill': 'black'
                                        }))] : null, !this.isCollapsed ? [_createElement('svg', {
                                            'width': '27',
                                            'height': '27',
                                            'viewBox': '0 0 12 28',
                                            'fill': 'none',
                                            'xmlns': 'http://www.w3.org/2000/svg',
                                            'key': '5730'
                                        }, _createElement('path', {
                                            'fillRule': 'evenodd',
                                            'clipRule': 'evenodd',
                                            'd': 'M8.20471 11.614L9.13023 10.6885L13.903 15.4612L18.6758 10.6885L19.6013 11.614L13.903 17.3123L8.20471 11.614Z',
                                            'fill': 'black'
                                        }))] : null),
                                    _createElement('div', {
                                        'className': 'facetbody' + (this.template === 'simpleFacet' && this.needShowMore && !this.moreValuesToShow ? ' expanded' : ''),
                                        'role': 'list',
                                        'key': '553'
                                    }, _createElement('div', { 'className': 'facetheader' }, _createElement('span', { 'className': 'facethitcount' }, this.selectedSize, ' selected'), _createElement('span', {
                                        'className': 'facetclear' + (!this.selectedSize ? ' disabled' : ''),
                                        'data-cm-role': 'reset-facet'
                                    }, '\n            Reset\n          ')), this.template === 'simpleFacet' ? _createElement('div', {
                                        'className': 'cmTemplate_simpleFacet',
                                        'key': '1391'
                                    }, this.showFilterInput ? _createElement('div', {
                                        'className': 'filter-input',
                                        'key': '1477'
                                    }, [this.filterInput(function () {
                                            return _createElement('div', {
                                                'className': 'input  cm_filterInput',
                                                'placeholder': 'Search'
                                            });
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })], this.inputNotEmpty ? _createElement('span', {
                                        'className': 'filter-input_clear-container',
                                        'onClick': this.clearInput,
                                        'key': '1798'
                                    }, _createElement('span', { 'className': 'filter-input_clear' }, '\u2715')) : null) : null, !this.ShowAlwaysValues.length && !this.Values.length ? [_createElement('div', {
                                            'className': 'facetdiv',
                                            'key': '19671'
                                        }, _createElement('span', { 'className': 'facetentrykey' }, 'No entries found'))] : null, _createElement('div', { 'className': 'facetvalues' + (this.showFilterInput && !this.moreValuesToShow ? ' overflowed' : '') }, _createElement.apply(this, [
                                        'div',
                                        { 'className': 'cmRepeater_ShowAlwaysValues' },
                                        _map(this.ShowAlwaysValues, repeatShowAlwaysValues1.bind(this))
                                    ]), _createElement.apply(this, [
                                        'div',
                                        { 'className': 'cmRepeater_Values' },
                                        _map(this.Values, repeatValues2.bind(this))
                                    ]), this.needShowMore ? _createElement('div', {
                                        'className': 'facetdiv cm_show-all-container',
                                        'key': '3798'
                                    }, _createElement('a', {
                                        'className': 'cm_show-all',
                                        'data-cm-role': 'toggle-show-more',
                                        'tabIndex': '0'
                                    }, this.moreValuesToShow ? [
                                        'View All ',
                                        this.valuesCount
                                    ] : null, !this.moreValuesToShow ? ['View Less'] : null)) : null)) : null, this.template === 'priceFacet' ? _createElement('div', {
                                        'className': 'cmTemplate_priceFacet',
                                        'key': '4144'
                                    }, !this.ranges.length ? [
                                        _createElement('div', { 'key': '42281' }, this.selectedRawRange[0], ' to ', this.selectedRawRange[1]),
                                        '\n  ',
                                        this.slider,
                                        '\n  ',
                                        _createElement('div', {
                                            'className': 'cm_flex',
                                            'key': '42283'
                                        }, _createElement('div', { 'className': 'cm_flex-grow' }, 'Min: ', this.rawMin), _createElement('div', {}, 'Max: ', this.rawMax))
                                    ] : null, this.ranges.length ? [_createElement.apply(this, [
                                            'div',
                                            {
                                                'className': 'cmRepeater_Values',
                                                'key': '44921'
                                            },
                                            _map(this.Values, repeatValues3.bind(this))
                                        ])] : null, [this.Inputs(function () {
                                            return _createElement('div', { 'className': 'input cm_Inputs' }, [this.inputMin(function () {
                                                    return _createElement('div', {
                                                        'placeholder': 'From',
                                                        'className': 'cm_inputMin'
                                                    });
                                                }, {
                                                    widgetName: 'undefined',
                                                    items: undefined
                                                })], _createElement('span', { 'className': 'separator' }, '\u2014'), [this.inputMax(function () {
                                                    return _createElement('div', {
                                                        'placeholder': 'To',
                                                        'className': 'cm_inputMax'
                                                    });
                                                }, {
                                                    widgetName: 'undefined',
                                                    items: undefined
                                                })], _createElement('button', {
                                                'type': 'button',
                                                'className': 'cm_btn button button--secondary',
                                                'onClick': this.setCustomRange
                                            }, '\n    Apply Price Range\n  '));
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })]) : null, this.template === 'selectFacet' ? _createElement('div', {
                                        'className': 'cmTemplate_selectFacet',
                                        'key': '7084'
                                    }, [this.select(function () {
                                            function repeatEntry1(popularEntries, unpopularEntries, isPopularField, entry, entryIndex) {
                                                return _createElement('option', {
                                                    'disabled': this.loading,
                                                    'value': entry.term,
                                                    'key': entry.value
                                                }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                            }
                                            function repeatEntry2(popularEntries, unpopularEntries, isPopularField, entry, entryIndex) {
                                                return _createElement('option', {
                                                    'disabled': this.loading,
                                                    'value': entry.term,
                                                    'key': entry.value
                                                }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                                            }
                                            function scopePopularEntriesUnpopularEntriesIsPopularField3() {
                                                var popularEntries = this.entries.filter(entry => entry.payload === 'Popular');
                                                var unpopularEntries = this.entries.filter(entry => entry.payload !== 'Popular');
                                                var isPopularField = popularEntries.length && unpopularEntries.length;
                                                return [
                                                    !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                                        'value': '',
                                                        'key': 'null-option'
                                                    }, '\n          Select ', this.title, '\n        ') : null,
                                                    this.loading ? _createElement('option', {
                                                        'key': 'loading-option',
                                                        'disabled': true
                                                    }, '...loading...') : null,
                                                    isPopularField ? [
                                                        _createElement('option', {
                                                            'className': 'cm_option_title',
                                                            'disabled': true,
                                                            'key': '10841'
                                                        }, 'Popular ', this.title === 'Make' ? 'Makes' : this.title === 'Model' ? 'Models' : 'Values'),
                                                        _map(popularEntries, repeatEntry1.bind(this, popularEntries, unpopularEntries, isPopularField)),
                                                        _createElement('option', {
                                                            'className': 'cm_option_title',
                                                            'disabled': true,
                                                            'key': '10844'
                                                        }, 'Additional ', this.title === 'Make' ? 'Makes' : this.title === 'Model' ? 'Models' : 'Values')
                                                    ] : null,
                                                    _map(isPopularField ? unpopularEntries : this.entries, repeatEntry2.bind(this, popularEntries, unpopularEntries, isPopularField))
                                                ];
                                            }
                                            function scopeShowSelectedValues4() {
                                                var showSelectedValues = this.selectedEntries.length;
                                                return _createElement('option', {
                                                    'key': '_current',
                                                    'value': '_current'
                                                }, showSelectedValues ? [this.selectedEntries.map(e => e.value).join(', ')] : null, !showSelectedValues ? [
                                                    'Select ',
                                                    this.title
                                                ] : null);
                                            }
                                            function repeatEntry5(entry, index) {
                                                return _createElement('div', {
                                                    'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                                                    'key': entry.value,
                                                    'onClick': () => this.onChange(entry.term)
                                                }, this.showCheckboxes ? _createElement('input', {
                                                    'type': 'checkbox',
                                                    'readOnly': true,
                                                    'checked': entry.selected,
                                                    'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                                                    'key': '3422'
                                                }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                                                    'className': 'cm_dropdown_hitCount',
                                                    'key': '3624'
                                                }, entry.hitCount) : null);
                                            }
                                            return _createElement('div', { 'className': 'cm_select' }, _createElement('div', { 'className': this.extraClassName }, !this.isSearchable ? _createElement('div', {
                                                'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                                                'key': '62'
                                            }, _createElement('select', {
                                                'className': 'cm_select_inner-select cm_select__pretty',
                                                'onChange': this.onChange,
                                                'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                                                'disabled': this.disabled,
                                                'aria-label': this.title
                                            }, this.useNativeDropdown ? scopePopularEntriesUnpopularEntriesIsPopularField3.apply(this, []) : null, !this.useNativeDropdown ? scopeShowSelectedValues4.apply(this, []) : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                                                        return _createElement('div', { 'className': 'cm_filterInput' });
                                                    }, {
                                                        widgetName: 'undefined',
                                                        items: undefined
                                                    })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                                                'div',
                                                {
                                                    'className': 'cm_dropdown cm_select_dropdown cm_hide',
                                                    'aria-label': this.title,
                                                    'key': '2725'
                                                },
                                                !this.hideNullOption ? _createElement('div', {
                                                    'className': 'option',
                                                    'key': 'null-option',
                                                    'onClick': () => this.onChange('')
                                                }, '\n      Select ', this.title, '\n    ') : null,
                                                this.loading ? _createElement('div', {
                                                    'className': 'option',
                                                    'key': 'null-option',
                                                    'disabled': true
                                                }, '...loading...') : null,
                                                !this.loading ? _map(this.entries, repeatEntry5.bind(this)) : null
                                            ]) : null));
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })]) : null, this.template === 'sliderFacet' ? scopeInchMm4.apply(this, []) : null, this.template === 'reviewFacet' ? _createElement('div', {
                                        'className': 'cmTemplate_reviewFacet',
                                        'key': '14903'
                                    }, _createElement.apply(this, [
                                        'div',
                                        { 'className': 'cmRepeater_ShowAlwaysValues' },
                                        _map(this.ShowAlwaysValues, repeatShowAlwaysValues5.bind(this))
                                    ]), _createElement.apply(this, [
                                        'div',
                                        { 'className': 'cmRepeater_Values' },
                                        _map(this.Values, repeatValues6.bind(this))
                                    ])) : null)
                                ] : null, this.template === 'toggleFacet' ? _createElement('div', {
                                    'className': 'cmTemplate_toggleFacet',
                                    'key': '18051'
                                }, _createElement('div', { 'className': 'facettitle cm_facet-toggle' }, _createElement('span', {}, this.name), _createElement('label', { 'className': 'cm_facet-toggle_switch' }, _createElement('input', {
                                    'className': 'cm_facet-toggle_input',
                                    'type': 'checkbox',
                                    'checked': this.isToggled,
                                    'onClick': this.toggleFacet
                                }), _createElement('span', { 'className': 'cm_facet-toggle_slider' })))) : null);
                            }, { count: undefined })];
                    }
                    return _createElement('div', { 'className': 'cm_facets' }, _createElement.apply(this, [
                        'div',
                        { 'className': 'cmRepeater_facets' },
                        _map(this.facets, repeatFacets1.bind(this))
                    ]));
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })]);
        }, {
            widgetName: 'undefined',
            items: undefined
        })]) : null, _createElement('div', { 'className': 'cm_main-content ' + (this.responseState === 'nothing' ? 'max-width' : '') }, _createElement('div', { 'id': 'itemsBlock' }, [this.message(function () {
            function onClick1() {
                window.Convermax.discardVehicle();
            }
            return _createElement('div', { 'className': 'cm_message' }, this.hasMessage('TrialExpired') ? [_createElement('div', { 'key': '240' }, _createElement('h2', { 'className': 'cm_message-header' }, 'YMM Search trial period has expired.'), '\n  Product information, including pricing and fitments, is outdated. This store runs on a limited trial version\n  of Convermax Year Make Model fitment search. Please contact\n  ', _createElement('a', {
                    'href': 'mailto:support@convermax.com',
                    'className': 'cm_message-link'
                }, 'support@convermax.com'), ' to complete setup\n  or cancel the integration.\n')] : null, this.hasMessage('AggressiveWheels') ? [_createElement('div', { 'key': '4980' }, '\n  Warning: Aggressive wheel options are displayed, but we cannot guarantee they will fit your selected\n  vehicle.\n')] : null, this.hasMessage('IncompatibleParts') && !this.notFilteredByVehicle ? [_createElement('div', {
                    'className': 'cm_message__incompatible',
                    'key': '7050'
                }, _createElement('div', {}, '\n    The products shown below will not fit a ', _createElement('span', {}, this.vehicleString), '.', _createElement('br', {}), '\n    The products results are based on your keyword search only.\n  '))] : null, this.isNoVehicleSpecificResults && this.isVehicleSelected && !this.notFilteredByVehicle && !this.hasMessage('NonVehicleProducts') ? [_createElement('div', {
                    'className': 'cm_message__universal',
                    'key': '10350'
                }, '\n  The system was unable to find any vehicle specific products that fit a\n  ', _createElement('span', {}, '\'', this.vehicleString, '\'.'), _createElement('br', {}), '\n  Click ', _createElement('a', {
                    'tabIndex': '0',
                    'className': 'cm_message-link',
                    'href': this.searchByVehicleUrl
                }, 'here'), ' to clear your vehicle\n  selection and browse all products in the category.\n')] : null, this.template === 'badRequest' ? _createElement('div', {
                'className': 'cmTemplate_badRequest',
                'key': '1568'
            }, 'Your request failed to process (', _createElement('b', {}, 'status:'), ' ', this.error.status, ', ', _createElement('b', {}, 'id:'), ' ', this.error.userId, ',\n', _createElement('b', {}, 'timestamp:'), ' ', this.error.timestamp, ', ', _createElement('b', {}, 'instance:'), ' ', this.error.instance, ').\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.startOver
            }, 'clear your search'), ' and try\nsearching for something else.\n') : null, this.template === 'corrected' ? _createElement('div', {
                'className': 'cmTemplate_corrected',
                'key': '1982'
            }, 'Your search for ', _createElement('span', { 'className': 'query' }, this.originalQuery), '\ndid not match any products. The system searched for\n', _createElement('span', { 'className': 'query' }, this.query), ' instead.\n') : null, this.template === 'networkError' ? _createElement('div', {
                'className': 'cmTemplate_networkError',
                'key': '2236'
            }, 'Network error\n', this.error ? [
                '(id: ',
                this.error.userId,
                ', timestamp: ',
                this.error.timestamp,
                ', instance: ',
                this.error.instance,
                ')'
            ] : null, '.\n', _createElement('br', {}), '\nThe search server could not be reached. Please try again later.\n') : null, this.template === 'nothing' ? _createElement('div', {
                'className': 'cmTemplate_nothing',
                'key': '2554'
            }, _createElement('div', {}, _createElement('div', { 'className': 'template-search__header page-width center' }, _createElement('div', { 'className': 'title-container' }, _createElement('div', { 'className': 'cm_border' }), _createElement('h1', { 'className': 'h2' }, 'OH NO!'), _createElement('div', { 'className': 'cm_border' })), _createElement('p', { 'role': 'status' }, this.pageType === 'search' && !this.isVehicleSelected ? ['\n        We can\'t seem to match parts for your search. Not to worry! One of our techs can help\xA0you.\n      '] : null, this.pageType === 'search' && this.isVehicleSelected ? [
                '\n        We can\'t seem to match parts for your `',
                this.vehicleString,
                '`. Not to worry! One of our techs can\n        help\xA0you.\n      '
            ] : null, this.pageType !== 'search' && !this.isFacetsSelected && !this.isVehicleSelected ? [
                '\n        We can\'t seem parts in ',
                this.pageType,
                '. Not to worry! One of our techs can help\xA0you.\n      '
            ] : null, this.pageType !== 'search' && !this.isFacetsSelected && this.isVehicleSelected ? [
                '\n        We can\'t seem to match parts for your `',
                this.vehicleString,
                '` in ',
                this.pageType,
                '. Not to worry! One of\n        our techs can help\xA0you.\n      '
            ] : null), _createElement('div', { 'className': 'buttons-container' }, this.pageType === 'category' ? _createElement('a', {
                'className': 'button',
                'onClick': onClick1.bind(this),
                'key': '3948'
            }, _createElement('div', { 'className': 'button-category-container' }, _createElement('span', { 'className': 'button-category' }, 'SHOP ALL ', window.Convermax.categoryTitle))) : null, this.pageType === 'search' ? _createElement('a', {
                'className': 'button button-shop-all',
                'onClick': this.startOver,
                'key': '4236'
            }, '\n        SHOP ALL\n      ') : null, _createElement('a', {
                'className': 'button',
                'href': '/pages/contact'
            }, 'CONTACT A TECH'))))) : null, this.template === 'timeout' ? _createElement('div', {
                'className': 'cmTemplate_timeout',
                'key': '4470'
            }, 'Your search request timed out.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.retry
            }, 'try your search again'), '.\n') : null, this.template === 'partial' ? _createElement('div', {
                'className': 'cmTemplate_partial',
                'key': '4689'
            }, 'The system was unable to find products to match your entire search of\n', _createElement('span', { 'className': 'query' }, this.originalQuery), '. A partial search was performed instead.\n') : null, this.template === 'unknown' ? _createElement('div', {
                'className': 'cmTemplate_unknown',
                'key': '4933'
            }, 'Your search request failed to process\n', this.error ? [
                '\n  (',
                this.error.status ? [
                    _createElement('b', { 'key': '50840' }, 'status:'),
                    ' ',
                    this.error.status,
                    ', '
                ] : null,
                this.error.userId ? [
                    _createElement('b', { 'key': '51710' }, 'id:'),
                    ' ',
                    this.error.userId,
                    ', '
                ] : null,
                this.error.timestamp ? [
                    _createElement('b', { 'key': '52540' }, 'timestamp:'),
                    ' ',
                    this.error.timestamp,
                    ', '
                ] : null,
                _createElement('b', { 'key': '50494' }, 'instance:'),
                ' ',
                this.error.instance,
                ')'
            ] : null, '.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.retry
            }, 'try your search again'), '.\n') : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.customMessage(function () {
            return _createElement('div', { 'className': 'cm_message cm_customMessage' }, []);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.SearchResult(function () {
            function repeatItems1(items, itemsIndex) {
                return [items(function () {
                        function mergeProps(inline, external) {
                            var res = Object.assign({}, inline, external);
                            if (inline.hasOwnProperty('style')) {
                                res.style = _.defaults(res.style, inline.style);
                            }
                            if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                res.className = external.className + ' ' + inline.className;
                            }
                            return res;
                        }
                        return _createElement('li', { 'className': 'collection-item' + (this.is_promotion_item ? ' promotion' : '') }, !this.is_promotional_item ? _createElement('div', {
                            'className': 'card-wrapper product-card-wrapper underline-links-hover',
                            'key': '95'
                        }, _createElement('div', {
                            'className': 'card card--card card--media color-background-1',
                            'style': { ratioPercent: '100%' }
                        }, _createElement('div', {
                            'className': 'card__inner ratio',
                            'style': { ratioPercent: '100%' }
                        }, _createElement('div', { 'className': 'card__media' }, _createElement('div', { 'className': 'media media--transparent' }, _createElement('img', {
                            'src': this.imageOrDefault(this.resizeImage(this.image)),
                            'alt': this.removeHTML(this.title),
                            'onError': this.onImageError,
                            'className': 'motion-reduce',
                            'loading': 'lazy'
                        }))), _createElement('div', { 'className': 'card__content' }, _createElement('div', { 'className': 'card__badge bottom left' }, this.on_sale ? _createElement('span', {
                            'className': 'badge badge--bottom-left color-accent-2',
                            'key': '753'
                        }, ' Sale ') : null, this.out_of_stock ? _createElement('span', {
                            'className': 'badge badge--bottom-left color-inverse',
                            'key': '854'
                        }, 'Out of stock') : null))), _createElement('div', { 'className': 'card__content' }, _createElement('div', { 'className': 'card__information' }, _createElement('div', mergeProps({ 'className': 'caption-large card__vendor' }, { dangerouslySetInnerHTML: { __html: this.vendor } })), _createElement('h3', { 'className': 'card__heading text-body' }, _createElement('a', mergeProps({
                            'href': this.url,
                            'className': 'full-unstyled-link'
                        }, { dangerouslySetInnerHTML: { __html: this.title } }))), _createElement('div', { 'className': 'card-information' }, _createElement('span', { 'className': 'caption-large card__sku' }, 'Part Number: ', _createElement('span', { dangerouslySetInnerHTML: { __html: this.sku } })), _createElement('div', { 'className': 'price' + (this.on_sale ? ' price--on-sale' : '') }, _createElement('div', { 'className': 'price__container' }, !this.on_sale ? _createElement('div', {
                            'className': 'price__regular',
                            'key': '1699'
                        }, _createElement('span', { 'className': 'price-item price-item--regular' }, this.formatPrice(this.price))) : null, this.on_sale ? _createElement('div', {
                            'className': 'price__sale',
                            'key': '1890'
                        }, _createElement('span', { 'className': 'price-item price-item--sale price-item--last' }, '\n                    ', this.formatPrice(this.price), '\n                  '), _createElement('span', {}, _createElement('s', { 'className': 'price-item price-item--regular' }, this.formatPrice(this.compare_at_price)))) : null))))))) : null, this.is_promotional_item ? _createElement('a', {
                            'href': this.promotion_url,
                            'className': 'image-promotion',
                            'key': '2382'
                        }, _createElement('img', {
                            'src': this.imageOrDefault(this.promotion_image_url),
                            'alt': this.promotion_image_alt || this.promotion_title,
                            'onError': this.onImageError,
                            'className': 'image-promotion--image',
                            'loading': 'lazy'
                        }), _createElement('div', { 'className': 'image-promotion--content color-inverse' }, this.promotion_title ? _createElement('h2', {
                            'className': 'image-promotion--title h3',
                            'key': '2733'
                        }, this.promotion_title) : null, this.promotion_button_text ? _createElement('a', {
                            'href': this.promotion_url,
                            'className': 'button',
                            'key': '2834'
                        }, '\n        ', this.promotion_button_text, '\n      ') : null)) : null);
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'card cm_SearchResult' }, _createElement.apply(this, [
                'ul',
                {
                    'id': 'product-grid',
                    'className': 'collection-grid cmRepeater_items'
                },
                _map(this.items, repeatItems1.bind(this))
            ]), [this.message(function () {
                    function onClick1() {
                        window.Convermax.discardVehicle();
                    }
                    return _createElement('div', { 'className': 'cm_message' }, this.hasMessage('TrialExpired') ? [_createElement('div', { 'key': '240' }, _createElement('h2', { 'className': 'cm_message-header' }, 'YMM Search trial period has expired.'), '\n  Product information, including pricing and fitments, is outdated. This store runs on a limited trial version\n  of Convermax Year Make Model fitment search. Please contact\n  ', _createElement('a', {
                            'href': 'mailto:support@convermax.com',
                            'className': 'cm_message-link'
                        }, 'support@convermax.com'), ' to complete setup\n  or cancel the integration.\n')] : null, this.hasMessage('AggressiveWheels') ? [_createElement('div', { 'key': '4980' }, '\n  Warning: Aggressive wheel options are displayed, but we cannot guarantee they will fit your selected\n  vehicle.\n')] : null, this.hasMessage('IncompatibleParts') && !this.notFilteredByVehicle ? [_createElement('div', {
                            'className': 'cm_message__incompatible',
                            'key': '7050'
                        }, _createElement('div', {}, '\n    The products shown below will not fit a ', _createElement('span', {}, this.vehicleString), '.', _createElement('br', {}), '\n    The products results are based on your keyword search only.\n  '))] : null, this.isNoVehicleSpecificResults && this.isVehicleSelected && !this.notFilteredByVehicle && !this.hasMessage('NonVehicleProducts') ? [_createElement('div', {
                            'className': 'cm_message__universal',
                            'key': '10350'
                        }, '\n  The system was unable to find any vehicle specific products that fit a\n  ', _createElement('span', {}, '\'', this.vehicleString, '\'.'), _createElement('br', {}), '\n  Click ', _createElement('a', {
                            'tabIndex': '0',
                            'className': 'cm_message-link',
                            'href': this.searchByVehicleUrl
                        }, 'here'), ' to clear your vehicle\n  selection and browse all products in the category.\n')] : null, this.template === 'badRequest' ? _createElement('div', {
                        'className': 'cmTemplate_badRequest',
                        'key': '1568'
                    }, 'Your request failed to process (', _createElement('b', {}, 'status:'), ' ', this.error.status, ', ', _createElement('b', {}, 'id:'), ' ', this.error.userId, ',\n', _createElement('b', {}, 'timestamp:'), ' ', this.error.timestamp, ', ', _createElement('b', {}, 'instance:'), ' ', this.error.instance, ').\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                        'tabIndex': '0',
                        'className': 'cm_message-link',
                        'onClick': this.startOver
                    }, 'clear your search'), ' and try\nsearching for something else.\n') : null, this.template === 'corrected' ? _createElement('div', {
                        'className': 'cmTemplate_corrected',
                        'key': '1982'
                    }, 'Your search for ', _createElement('span', { 'className': 'query' }, this.originalQuery), '\ndid not match any products. The system searched for\n', _createElement('span', { 'className': 'query' }, this.query), ' instead.\n') : null, this.template === 'networkError' ? _createElement('div', {
                        'className': 'cmTemplate_networkError',
                        'key': '2236'
                    }, 'Network error\n', this.error ? [
                        '(id: ',
                        this.error.userId,
                        ', timestamp: ',
                        this.error.timestamp,
                        ', instance: ',
                        this.error.instance,
                        ')'
                    ] : null, '.\n', _createElement('br', {}), '\nThe search server could not be reached. Please try again later.\n') : null, this.template === 'nothing' ? _createElement('div', {
                        'className': 'cmTemplate_nothing',
                        'key': '2554'
                    }, _createElement('div', {}, _createElement('div', { 'className': 'template-search__header page-width center' }, _createElement('div', { 'className': 'title-container' }, _createElement('div', { 'className': 'cm_border' }), _createElement('h1', { 'className': 'h2' }, 'OH NO!'), _createElement('div', { 'className': 'cm_border' })), _createElement('p', { 'role': 'status' }, this.pageType === 'search' && !this.isVehicleSelected ? ['\n        We can\'t seem to match parts for your search. Not to worry! One of our techs can help\xA0you.\n      '] : null, this.pageType === 'search' && this.isVehicleSelected ? [
                        '\n        We can\'t seem to match parts for your `',
                        this.vehicleString,
                        '`. Not to worry! One of our techs can\n        help\xA0you.\n      '
                    ] : null, this.pageType !== 'search' && !this.isFacetsSelected && !this.isVehicleSelected ? [
                        '\n        We can\'t seem parts in ',
                        this.pageType,
                        '. Not to worry! One of our techs can help\xA0you.\n      '
                    ] : null, this.pageType !== 'search' && !this.isFacetsSelected && this.isVehicleSelected ? [
                        '\n        We can\'t seem to match parts for your `',
                        this.vehicleString,
                        '` in ',
                        this.pageType,
                        '. Not to worry! One of\n        our techs can help\xA0you.\n      '
                    ] : null), _createElement('div', { 'className': 'buttons-container' }, this.pageType === 'category' ? _createElement('a', {
                        'className': 'button',
                        'onClick': onClick1.bind(this),
                        'key': '3948'
                    }, _createElement('div', { 'className': 'button-category-container' }, _createElement('span', { 'className': 'button-category' }, 'SHOP ALL ', window.Convermax.categoryTitle))) : null, this.pageType === 'search' ? _createElement('a', {
                        'className': 'button button-shop-all',
                        'onClick': this.startOver,
                        'key': '4236'
                    }, '\n        SHOP ALL\n      ') : null, _createElement('a', {
                        'className': 'button',
                        'href': '/pages/contact'
                    }, 'CONTACT A TECH'))))) : null, this.template === 'timeout' ? _createElement('div', {
                        'className': 'cmTemplate_timeout',
                        'key': '4470'
                    }, 'Your search request timed out.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                        'tabIndex': '0',
                        'className': 'cm_message-link',
                        'onClick': this.retry
                    }, 'try your search again'), '.\n') : null, this.template === 'partial' ? _createElement('div', {
                        'className': 'cmTemplate_partial',
                        'key': '4689'
                    }, 'The system was unable to find products to match your entire search of\n', _createElement('span', { 'className': 'query' }, this.originalQuery), '. A partial search was performed instead.\n') : null, this.template === 'unknown' ? _createElement('div', {
                        'className': 'cmTemplate_unknown',
                        'key': '4933'
                    }, 'Your search request failed to process\n', this.error ? [
                        '\n  (',
                        this.error.status ? [
                            _createElement('b', { 'key': '50840' }, 'status:'),
                            ' ',
                            this.error.status,
                            ', '
                        ] : null,
                        this.error.userId ? [
                            _createElement('b', { 'key': '51710' }, 'id:'),
                            ' ',
                            this.error.userId,
                            ', '
                        ] : null,
                        this.error.timestamp ? [
                            _createElement('b', { 'key': '52540' }, 'timestamp:'),
                            ' ',
                            this.error.timestamp,
                            ', '
                        ] : null,
                        _createElement('b', { 'key': '50494' }, 'instance:'),
                        ' ',
                        this.error.instance,
                        ')'
                    ] : null, '.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                        'tabIndex': '0',
                        'className': 'cm_message-link',
                        'onClick': this.retry
                    }, 'try your search again'), '.\n') : null);
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })]);
        }, {
            widgetName: 'results',
            items: undefined
        })], [this.SearchHeader(function () {
            return _createElement('div', { 'className': 'cm_SearchHeader__bottom cm_SearchHeader' }, _createElement('span', { 'className': 'cm_total-hits' }, 'Showing ', this.pageTo, ' out of ', this.totalHits));
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.SearchResult(function () {
            return _createElement('div', { 'className': 'cm_load-more_container cm_SearchResult' }, [this.loadMore(function () {
                    return _createElement('div', { 'className': 'cm_loadMore' }, _createElement('div', { 'className': 'cm_load-more' }, this.isNotAllItemsReceived ? [
                        this.isActive ? [this.loading] : null,
                        !this.isActive ? [[this.loadMoreButton(function () {
                                    return _createElement('div', {
                                        'className': 'btn btn-default btn-inverse button button--primary cm_loadMoreButton',
                                        'onClick': this.activate
                                    }, '\n        Show me more products\n      ');
                                }, {
                                    widgetName: 'undefined',
                                    items: undefined
                                })]] : null
                    ] : null));
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })]);
        }, {
            widgetName: 'undefined',
            items: undefined
        })])));
}
        export const componentNames = ["cm:filterChips","cm:FilterChips","cm:facetToggle","cm:SearchHeader","cm:CustomFacetDialogButton","cm:filterInput","cm:facetInput","cm:PumpSearchFacetPanel","cm:filterInput","cm:facetToggle","cm:SearchVehicleWidget","cm:filterInput","cm:inputMin","cm:inputMax","cm:Inputs","cm:filterInput","cm:select","cm:inputMin","cm:inputMax","cm:Inputs","cm:facets","cm:Facets","cm:message","cm:customMessage","cm:message","cm:SearchResult","cm:SearchHeader","cm:loadMoreButton","cm:loadMore","cm:SearchResult"]