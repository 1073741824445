
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function garageRT () {
    return _createElement('div', { 'className': 'cm_garage' }, _createElement('a', {
        'className': 'cm_toggle-dropdown garage-button',
        'title': 'Garage: ' + this.size + ' item(s)',
        'tabIndex': '0',
        'role': 'button'
    }, _createElement('div', { 'className': 'garage-icon-container' }, [_createElement('svg', {
            'width': '16',
            'height': '18',
            'viewBox': '0 0 16 18',
            'fill': 'none',
            'xmlns': 'http://www.w3.org/2000/svg',
            'key': '1740'
        }, _createElement('path', { 'd': 'M0 17.5713V6.14275L8 0.428467L16 6.14275V17.5713H14.5V6.85704L8 2.21418L1.5 6.85704V17.5713H0ZM4.5 16.1428H11.5V13.5237H4.5V16.1428ZM4.5 12.0951H11.5V9.47609H4.5V12.0951ZM3 17.5713V8.04751H13V17.5713H3Z' }))], this.size ? [_createElement('span', {
            'className': 'garage-size cart-count-bubble',
            'key': 'garage-size'
        }, this.size)] : null), _createElement('span', { 'className': 'garage-label' }, 'Add my vehicle')), [this.garageDropdown(function () {
            function onClick1(e) {
                $(e.target)?.closest('.cm_garage')?.find('.cm_toggle-dropdown')?.[0]?.click();
            }
            function repeatItems2(items, itemsIndex) {
                return [items(function () {
                        return _createElement('div', { 'className': 'garage-item' + (this.isSelected ? ' selected' : '') }, [_createElement('svg', {
                                'width': '17',
                                'height': '16',
                                'viewBox': '0 0 17 16',
                                'fill': 'none',
                                'xmlns': 'http://www.w3.org/2000/svg',
                                'key': '970'
                            }, _createElement('path', {
                                'd': 'M7.14299 1.19198C7.9192 0.51017 9.0808 0.510169 9.85701 1.19198C10.2816 1.56497 10.841 1.74673 11.4038 1.69457C12.4325 1.59921 13.3723 2.28199 13.5995 3.28983C13.7238 3.84116 14.0695 4.31703 14.5555 4.60561C15.4438 5.13314 15.8027 6.23789 15.3941 7.1868C15.1706 7.7059 15.1706 8.2941 15.3941 8.8132C15.8027 9.76211 15.4438 10.8669 14.5555 11.3944C14.0695 11.683 13.7238 12.1588 13.5995 12.7102C13.3723 13.718 12.4325 14.4008 11.4038 14.3054C10.841 14.2533 10.2816 14.435 9.85701 14.808C9.0808 15.4898 7.9192 15.4898 7.14299 14.808C6.71837 14.435 6.15895 14.2533 5.59619 14.3054C4.56747 14.4008 3.62771 13.718 3.4005 12.7102C3.27621 12.1588 2.93047 11.683 2.44453 11.3944C1.55623 10.8669 1.19727 9.76211 1.60585 8.8132C1.82936 8.2941 1.82936 7.7059 1.60585 7.1868C1.19727 6.23789 1.55623 5.13314 2.44453 4.60561C2.93047 4.31703 3.27621 3.84116 3.4005 3.28983C3.62771 2.28199 4.56747 1.59921 5.59619 1.69457C6.15895 1.74673 6.71837 1.56497 7.14299 1.19198Z',
                                'fill': '#EA0029'
                            }), _createElement('path', {
                                'd': 'M7.01462 9.52793L5.34624 7.76271L5.23723 7.64737L5.12821 7.76271L4.53575 8.38956L4.43836 8.49259L4.53575 8.59563L6.90561 11.103L7.01462 11.2184L7.12364 11.103L12.2019 5.73001L12.2993 5.62698L12.2019 5.52394L11.6094 4.89709L11.5004 4.78175L11.3914 4.89709L7.01462 9.52793Z',
                                'fill': 'white',
                                'stroke': 'white',
                                'strokeWidth': '0.3'
                            }))], _createElement('span', {
                            'className': 'select-model',
                            'data-cm-role': 'select-vehicle'
                        }, this.vehicleString), _createElement('span', {
                            'className': 'remove-model',
                            'data-cm-role': 'remove-vehicle'
                        }, [_createElement('svg', {
                                'width': '17',
                                'height': '16',
                                'viewBox': '0 0 17 16',
                                'fill': 'none',
                                'xmlns': 'http://www.w3.org/2000/svg',
                                'key': '17000'
                            }, _createElement('path', {
                                'd': 'M13.5 3L3.5 13',
                                'strokeLinecap': 'round',
                                'strokeLinejoin': 'round'
                            }), _createElement('path', {
                                'd': 'M3.5 3L13.5 13',
                                'strokeLinecap': 'round',
                                'strokeLinejoin': 'round'
                            }))]));
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'cm_garageDropdown' }, _createElement('div', { 'className': 'garage-header' }, _createElement('h6', { 'className': 'garage-title' }, 'MY VEHICLE', !this.items || this.items.length >= 2 ? 'S' : ''), [_createElement('svg', {
                    'className': 'garage-close',
                    'width': '14',
                    'height': '14',
                    'viewBox': '0 0 14 14',
                    'fill': 'none',
                    'xmlns': 'http://www.w3.org/2000/svg',
                    'onClick': onClick1.bind(this),
                    'key': '1770'
                }, _createElement('path', {
                    'd': 'M14 1.056L12.944 0L7 5.944L1.056 0L0 1.056L5.944 7L0 12.944L1.056 14L7 8.056L12.944 14L14 12.944L8.057 7L14 1.056Z',
                    'fill': '#16181B'
                }))]), this.template === 'list' ? _createElement('div', {
                'className': 'cmTemplate_list',
                'key': '601'
            }, _createElement('div', { 'className': 'garage-content' }, _createElement.apply(this, [
                'div',
                { 'className': 'cmRepeater_items' },
                _map(this.items, repeatItems2.bind(this))
            ]), _createElement('div', {
                'className': 'clear-garage',
                'data-cm-role': 'clear-garage'
            }, 'Clear garage'), [this.vehicleWidget(function () {
                    function repeatSelects1(selects, selectsIndex) {
                        return [selects(function () {
                                function repeatEntry1(popularEntries, unpopularEntries, isPopularField, entry, entryIndex) {
                                    return _createElement('option', {
                                        'disabled': this.loading,
                                        'value': entry.term,
                                        'key': entry.value
                                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                }
                                function repeatEntry2(popularEntries, unpopularEntries, isPopularField, entry, entryIndex) {
                                    return _createElement('option', {
                                        'disabled': this.loading,
                                        'value': entry.term,
                                        'key': entry.value
                                    }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                                }
                                function scopePopularEntriesUnpopularEntriesIsPopularField3() {
                                    var popularEntries = this.entries.filter(entry => entry.payload === 'Popular');
                                    var unpopularEntries = this.entries.filter(entry => entry.payload !== 'Popular');
                                    var isPopularField = popularEntries.length && unpopularEntries.length;
                                    return [
                                        !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                            'value': '',
                                            'key': 'null-option'
                                        }, '\n          Select ', this.title, '\n        ') : null,
                                        this.loading ? _createElement('option', {
                                            'key': 'loading-option',
                                            'disabled': true
                                        }, '...loading...') : null,
                                        isPopularField ? [
                                            _createElement('option', {
                                                'className': 'cm_option_title',
                                                'disabled': true,
                                                'key': '10611'
                                            }, 'Popular ', this.title === 'Make' ? 'Makes' : this.title === 'Model' ? 'Models' : 'Values'),
                                            _map(popularEntries, repeatEntry1.bind(this, popularEntries, unpopularEntries, isPopularField)),
                                            _createElement('option', {
                                                'className': 'cm_option_title',
                                                'disabled': true,
                                                'key': '10614'
                                            }, 'Additional ', this.title === 'Make' ? 'Makes' : this.title === 'Model' ? 'Models' : 'Values')
                                        ] : null,
                                        _map(isPopularField ? unpopularEntries : this.entries, repeatEntry2.bind(this, popularEntries, unpopularEntries, isPopularField))
                                    ];
                                }
                                function scopeShowSelectedValues4() {
                                    var showSelectedValues = this.selectedEntries.length;
                                    return _createElement('option', {
                                        'key': '_current',
                                        'value': '_current'
                                    }, showSelectedValues ? [this.selectedEntries.map(e => e.value).join(', ')] : null, !showSelectedValues ? [
                                        'Select ',
                                        this.title
                                    ] : null);
                                }
                                function repeatEntry5(entry, index) {
                                    return _createElement('div', {
                                        'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                                        'key': entry.value,
                                        'onClick': () => this.onChange(entry.term)
                                    }, this.showCheckboxes ? _createElement('input', {
                                        'type': 'checkbox',
                                        'readOnly': true,
                                        'checked': entry.selected,
                                        'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                                        'key': '3399'
                                    }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                                        'className': 'cm_dropdown_hitCount',
                                        'key': '3601'
                                    }, entry.hitCount) : null);
                                }
                                return _createElement('div', { 'className': this.extraClassName }, !this.isSearchable ? _createElement('div', {
                                    'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                                    'key': '39'
                                }, _createElement('select', {
                                    'className': 'cm_select_inner-select cm_select__pretty',
                                    'onChange': this.onChange,
                                    'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                                    'disabled': this.disabled,
                                    'aria-label': this.title
                                }, this.useNativeDropdown ? scopePopularEntriesUnpopularEntriesIsPopularField3.apply(this, []) : null, !this.useNativeDropdown ? scopeShowSelectedValues4.apply(this, []) : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                                            return _createElement('div', { 'className': 'cm_filterInput' });
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                                    'div',
                                    {
                                        'className': 'cm_dropdown cm_select_dropdown cm_hide',
                                        'aria-label': this.title,
                                        'key': '2702'
                                    },
                                    !this.hideNullOption ? _createElement('div', {
                                        'className': 'option',
                                        'key': 'null-option',
                                        'onClick': () => this.onChange('')
                                    }, '\n      Select ', this.title, '\n    ') : null,
                                    this.loading ? _createElement('div', {
                                        'className': 'option',
                                        'key': 'null-option',
                                        'disabled': true
                                    }, '...loading...') : null,
                                    !this.loading ? _map(this.entries, repeatEntry5.bind(this)) : null
                                ]) : null);
                            }, { count: undefined })];
                    }
                    return _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__garage cm_vehicleWidget' }, _createElement('div', { 'className': 'cm_vehicle-widget_dropdowns' }, _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
                        'div',
                        { 'className': 'cm_vehicle-widget_select-container  cmRepeater_selects' },
                        _map(this.selects, repeatSelects1.bind(this))
                    ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
                        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__go button button--primary',
                        'onClick': this.selectVehicle,
                        'disabled': !this.allowToSetVehicle,
                        'role': 'button',
                        'tabIndex': this.allowToSetVehicle ? '0' : '-1'
                    }, '\n    ', window.document.body.classList.contains('template-product') ? 'Verify fitment' : 'GO', '\n  '), _createElement('div', {
                        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__clear button button--secondary',
                        'onClick': this.discardVehicle,
                        'disabled': !this.allowToDiscardVehicle,
                        'role': 'button',
                        'tabIndex': this.allowToDiscardVehicle ? '0' : '-1'
                    }, [_createElement('svg', {
                            'width': '24',
                            'height': '24',
                            'viewBox': '0 0 24 24',
                            'fill': 'none',
                            'xmlns': 'http://www.w3.org/2000/svg',
                            'key': '67830'
                        }, _createElement('g', { 'id': 'restart_alt' }, _createElement('mask', {
                            'id': 'mask0_1239_10799',
                            'style': { maskType: 'alpha' },
                            'maskUnits': 'userSpaceOnUse',
                            'x': '0',
                            'y': '0',
                            'width': '24',
                            'height': '24'
                        }, _createElement('rect', {
                            'id': 'Bounding box',
                            'width': '24',
                            'height': '24',
                            'fill': '#D9D9D9'
                        })), _createElement('g', { 'mask': 'url(#mask0_1239_10799)' }, _createElement('path', {
                            'id': 'restart_alt_2',
                            'd': 'M11 20.4307C9.12434 20.1871 7.57211 19.3609 6.34327 17.9519C5.11442 16.5429 4.5 14.8923 4.5 13C4.5 11.9833 4.70064 11.0093 5.10192 10.0779C5.50321 9.14649 6.06538 8.32822 6.78845 7.62309L7.85765 8.69232C7.24355 9.25257 6.78042 9.90288 6.46825 10.6433C6.15607 11.3836 5.99997 12.1692 5.99997 13C5.99997 14.4667 6.46824 15.7593 7.40477 16.8779C8.34132 17.9965 9.53972 18.6808 11 18.9308V20.4307ZM13 20.45V18.95C14.4436 18.6577 15.6378 17.958 16.5827 16.8509C17.5275 15.7439 18 14.4602 18 13C18 11.3333 17.4166 9.91666 16.25 8.74999C15.0833 7.58332 13.6666 6.99999 12 6.99999H11.6461L12.9961 8.35002L11.9423 9.40384L8.78848 6.25002L11.9423 3.09619L12.9961 4.15002L11.6461 5.50002H12C14.0923 5.50002 15.8653 6.22694 17.3192 7.68079C18.773 9.13462 19.5 10.9077 19.5 13C19.5 14.882 18.8839 16.5243 17.6519 17.9269C16.4198 19.3294 14.8692 20.1705 13 20.45Z',
                            'fill': 'white'
                        }))))], _createElement('span', {}, 'Reset'))))));
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })])) : null, this.template === 'empty' ? _createElement('div', {
                'className': 'cmTemplate_empty',
                'key': '13396'
            }, _createElement('div', { 'className': 'garage-content' }, _createElement('p', { 'className': 'vehicle-advantages' }, [_createElement('svg', {
                    'width': '11',
                    'height': '9',
                    'viewBox': '0 0 11 9',
                    'fill': 'none',
                    'xmlns': 'http://www.w3.org/2000/svg',
                    'key': '135430'
                }, _createElement('path', {
                    'd': 'M1 3.9L4.375 7.5L10 1.5',
                    'stroke': '#16181B',
                    'strokeWidth': '2'
                }))], 'Store your vehicle in the garage\n    '), _createElement('p', { 'className': 'vehicle-advantages' }, [_createElement('svg', {
                    'width': '11',
                    'height': '9',
                    'viewBox': '0 0 11 9',
                    'fill': 'none',
                    'xmlns': 'http://www.w3.org/2000/svg',
                    'key': '138230'
                }, _createElement('path', {
                    'd': 'M1 3.9L4.375 7.5L10 1.5',
                    'stroke': '#16181B',
                    'strokeWidth': '2'
                }))], 'Get products for your vehicle\n    '), _createElement('p', { 'className': 'vehicle-advantages' }, [_createElement('svg', {
                    'width': '11',
                    'height': '9',
                    'viewBox': '0 0 11 9',
                    'fill': 'none',
                    'xmlns': 'http://www.w3.org/2000/svg',
                    'key': '141000'
                }, _createElement('path', {
                    'd': 'M1 3.9L4.375 7.5L10 1.5',
                    'stroke': '#16181B',
                    'strokeWidth': '2'
                }))], 'Easily find the parts you need\n    '), [this.vehicleWidget(function () {
                    function repeatSelects1(selects, selectsIndex) {
                        return [selects(function () {
                                function repeatEntry1(popularEntries, unpopularEntries, isPopularField, entry, entryIndex) {
                                    return _createElement('option', {
                                        'disabled': this.loading,
                                        'value': entry.term,
                                        'key': entry.value
                                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                }
                                function repeatEntry2(popularEntries, unpopularEntries, isPopularField, entry, entryIndex) {
                                    return _createElement('option', {
                                        'disabled': this.loading,
                                        'value': entry.term,
                                        'key': entry.value
                                    }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                                }
                                function scopePopularEntriesUnpopularEntriesIsPopularField3() {
                                    var popularEntries = this.entries.filter(entry => entry.payload === 'Popular');
                                    var unpopularEntries = this.entries.filter(entry => entry.payload !== 'Popular');
                                    var isPopularField = popularEntries.length && unpopularEntries.length;
                                    return [
                                        !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                            'value': '',
                                            'key': 'null-option'
                                        }, '\n          Select ', this.title, '\n        ') : null,
                                        this.loading ? _createElement('option', {
                                            'key': 'loading-option',
                                            'disabled': true
                                        }, '...loading...') : null,
                                        isPopularField ? [
                                            _createElement('option', {
                                                'className': 'cm_option_title',
                                                'disabled': true,
                                                'key': '10611'
                                            }, 'Popular ', this.title === 'Make' ? 'Makes' : this.title === 'Model' ? 'Models' : 'Values'),
                                            _map(popularEntries, repeatEntry1.bind(this, popularEntries, unpopularEntries, isPopularField)),
                                            _createElement('option', {
                                                'className': 'cm_option_title',
                                                'disabled': true,
                                                'key': '10614'
                                            }, 'Additional ', this.title === 'Make' ? 'Makes' : this.title === 'Model' ? 'Models' : 'Values')
                                        ] : null,
                                        _map(isPopularField ? unpopularEntries : this.entries, repeatEntry2.bind(this, popularEntries, unpopularEntries, isPopularField))
                                    ];
                                }
                                function scopeShowSelectedValues4() {
                                    var showSelectedValues = this.selectedEntries.length;
                                    return _createElement('option', {
                                        'key': '_current',
                                        'value': '_current'
                                    }, showSelectedValues ? [this.selectedEntries.map(e => e.value).join(', ')] : null, !showSelectedValues ? [
                                        'Select ',
                                        this.title
                                    ] : null);
                                }
                                function repeatEntry5(entry, index) {
                                    return _createElement('div', {
                                        'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                                        'key': entry.value,
                                        'onClick': () => this.onChange(entry.term)
                                    }, this.showCheckboxes ? _createElement('input', {
                                        'type': 'checkbox',
                                        'readOnly': true,
                                        'checked': entry.selected,
                                        'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                                        'key': '3399'
                                    }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                                        'className': 'cm_dropdown_hitCount',
                                        'key': '3601'
                                    }, entry.hitCount) : null);
                                }
                                return _createElement('div', { 'className': this.extraClassName }, !this.isSearchable ? _createElement('div', {
                                    'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                                    'key': '39'
                                }, _createElement('select', {
                                    'className': 'cm_select_inner-select cm_select__pretty',
                                    'onChange': this.onChange,
                                    'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                                    'disabled': this.disabled,
                                    'aria-label': this.title
                                }, this.useNativeDropdown ? scopePopularEntriesUnpopularEntriesIsPopularField3.apply(this, []) : null, !this.useNativeDropdown ? scopeShowSelectedValues4.apply(this, []) : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                                            return _createElement('div', { 'className': 'cm_filterInput' });
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                                    'div',
                                    {
                                        'className': 'cm_dropdown cm_select_dropdown cm_hide',
                                        'aria-label': this.title,
                                        'key': '2702'
                                    },
                                    !this.hideNullOption ? _createElement('div', {
                                        'className': 'option',
                                        'key': 'null-option',
                                        'onClick': () => this.onChange('')
                                    }, '\n      Select ', this.title, '\n    ') : null,
                                    this.loading ? _createElement('div', {
                                        'className': 'option',
                                        'key': 'null-option',
                                        'disabled': true
                                    }, '...loading...') : null,
                                    !this.loading ? _map(this.entries, repeatEntry5.bind(this)) : null
                                ]) : null);
                            }, { count: undefined })];
                    }
                    return _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__garage cm_vehicleWidget' }, _createElement('div', { 'className': 'cm_vehicle-widget_dropdowns' }, _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
                        'div',
                        { 'className': 'cm_vehicle-widget_select-container  cmRepeater_selects' },
                        _map(this.selects, repeatSelects1.bind(this))
                    ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
                        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__go button button--primary',
                        'onClick': this.selectVehicle,
                        'disabled': !this.allowToSetVehicle,
                        'role': 'button',
                        'tabIndex': this.allowToSetVehicle ? '0' : '-1'
                    }, '\n    ', window.document.body.classList.contains('template-product') ? 'Verify fitment' : 'GO', '\n  '), _createElement('div', {
                        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__clear button button--secondary',
                        'onClick': this.discardVehicle,
                        'disabled': !this.allowToDiscardVehicle,
                        'role': 'button',
                        'tabIndex': this.allowToDiscardVehicle ? '0' : '-1'
                    }, [_createElement('svg', {
                            'width': '24',
                            'height': '24',
                            'viewBox': '0 0 24 24',
                            'fill': 'none',
                            'xmlns': 'http://www.w3.org/2000/svg',
                            'key': '67830'
                        }, _createElement('g', { 'id': 'restart_alt' }, _createElement('mask', {
                            'id': 'mask0_1239_10799',
                            'style': { maskType: 'alpha' },
                            'maskUnits': 'userSpaceOnUse',
                            'x': '0',
                            'y': '0',
                            'width': '24',
                            'height': '24'
                        }, _createElement('rect', {
                            'id': 'Bounding box',
                            'width': '24',
                            'height': '24',
                            'fill': '#D9D9D9'
                        })), _createElement('g', { 'mask': 'url(#mask0_1239_10799)' }, _createElement('path', {
                            'id': 'restart_alt_2',
                            'd': 'M11 20.4307C9.12434 20.1871 7.57211 19.3609 6.34327 17.9519C5.11442 16.5429 4.5 14.8923 4.5 13C4.5 11.9833 4.70064 11.0093 5.10192 10.0779C5.50321 9.14649 6.06538 8.32822 6.78845 7.62309L7.85765 8.69232C7.24355 9.25257 6.78042 9.90288 6.46825 10.6433C6.15607 11.3836 5.99997 12.1692 5.99997 13C5.99997 14.4667 6.46824 15.7593 7.40477 16.8779C8.34132 17.9965 9.53972 18.6808 11 18.9308V20.4307ZM13 20.45V18.95C14.4436 18.6577 15.6378 17.958 16.5827 16.8509C17.5275 15.7439 18 14.4602 18 13C18 11.3333 17.4166 9.91666 16.25 8.74999C15.0833 7.58332 13.6666 6.99999 12 6.99999H11.6461L12.9961 8.35002L11.9423 9.40384L8.78848 6.25002L11.9423 3.09619L12.9961 4.15002L11.6461 5.50002H12C14.0923 5.50002 15.8653 6.22694 17.3192 7.68079C18.773 9.13462 19.5 10.9077 19.5 13C19.5 14.882 18.8839 16.5243 17.6519 17.9269C16.4198 19.3294 14.8692 20.1705 13 20.45Z',
                            'fill': 'white'
                        }))))], _createElement('span', {}, 'Reset'))))));
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })])) : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })]);
}
        export const componentNames = ["cm:filterInput","cm:vehicleWidget","cm:filterInput","cm:vehicleWidget","cm:garageDropdown"]