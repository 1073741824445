const baseFitmentFields = ['Year', 'Make', 'Model', 'Engine'];
const extraFitmentFields = [];

const isProductPage = window.document.body.classList.contains('template-product');

globalThis.Convermax.categoryTitle = window.document
  .querySelector('meta[property="og:title"]')
  ?.attributes?.getNamedItem('content')?.textContent;

const pumpSearchPanelConfig = {
  isAutoRedirectDisabled: true,
  inputFields: ['Horsepower'],
  requiredFields: ['CarbOrEFI', 'PowerAdder', 'Fuel', 'Horsepower'],
  selectFields: ['CarbOrEFI', 'PowerAdder', 'Fuel', 'Mounting'],
  useNativeDropdown: true,
};

globalThis.Convermax.handlePumpFinderDropdownOpen = (e) => {
  const dropdown = e.target
    .closest('.cm_req-panel_pump-dropdown-container')
    .querySelector('.cm_req-panel_pump-dropdown');
  if (dropdown) {
    if (dropdown.classList.contains('cm_hide')) {
      dropdown.classList.remove('cm_hide');
      window.document.body.classList.add('cm_pump_dropdown');
    } else {
      dropdown.classList.add('cm_hide');
      document.body.classList.remove('cm_pump_dropdown');
    }
  }
};
const AfterInit = () => {
  window.addEventListener('click', (e) => {
    const { document } = window;

    if (
      document.body.classList.contains('cm_pump_dropdown') &&
      !e.target.closest('.cm_req-panel_pump-dropdown-container')
    ) {
      document.body.classList.remove('cm_pump_dropdown');
      document.body
        .querySelectorAll('.cm_req-panel_pump-dropdown')
        .forEach((el) => !el.classList.contains('cm_hide') && el.classList.add('cm_hide'));
    }
  });
};

export default {
  platform: 'shopify',
  AfterInit,
  SearchRequestDefaults: {
    pageSize: 12,
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    isAutoRedirectDisabled: true,
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'SearchPage',
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: '#cm-collection-page',
      template: 'SearchPage',
    },
    {
      name: 'FilterChips',
      type: 'FacetPanel',
    },
    {
      name: 'Facets',
      type: 'FacetPanel',
      ignoreFields: ['Horsepower'],
    },
    {
      name: 'CustomFacetDialogFacets',
      type: 'FacetPanel',
      ignoreFields: ['Horsepower'],
    },
    {
      name: 'CustomFacetDialogFilterChips',
      type: 'FacetPanel',
    },
    {
      name: 'SearchBox',
      location: { selector: 'predictive-search.search-modal__form', class: 'cm_search-box-root__desktop' },
      template: 'SearchBox',
    },
    {
      name: 'SearchBoxDialogButton',
      location: { replace: 'details-modal.header__search', class: 'header__icon cm_desktop-hide' },
      template: 'searchBox/dialogButtonMobile',
    },
    {
      name: 'SearchResult',
      instantInfiniteScroll: true,
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'collection_sort': 'Featured',
        'title': 'Name, A-Z',
        'title:desc': 'Name, Z-A',
        'price': 'Price, Low to High',
        'price:desc': 'Price, High to Low',
      },
      facetToggleConfig: {
        toggledFacet: { field: 'Universal', term: 'True' },
        visibleIfFields: baseFitmentFields.filter((field) => field !== 'Engine'),
      },
      sortSelectClass: 'cm_select__pretty',
    },
    {
      name: 'PumpSearchPanel',
      type: 'RequestPanel',
      location: '#cm-pump-search-panel',
      template: 'RequestPumpPanelContainer',
      ...pumpSearchPanelConfig,
      shouldBeIsolated: () => true,
    },
    {
      name: 'PumpSearchFacetPanel',
      type: 'RequestPanel',
      ...pumpSearchPanelConfig,
    },
    {
      name: 'PumpSearchDropdownPanel',
      type: 'RequestPanel',
      location: { selector: '#cm-pump-search-panel-dropdown', class: 'cm_mobile-hide' },
      template: 'RequestPumpDropdownPanel',
      ...pumpSearchPanelConfig,
      shouldBeIsolated: () => true,
    },
    {
      name: 'PumpSearchDropdownPanelMobile',
      type: 'RequestPanel',
      location: {
        selector: '#cm-mobile-pump-search-panel-dropdown',
        class: 'cm_req-panel_pump-mobile-dropdown cm_desktop-hide',
      },
      template: 'RequestPumpDropdownPanel',
      ...pumpSearchPanelConfig,
      shouldBeIsolated: () => true,
    },
    {
      name: 'HomePumpSearchPanel_tab',
      type: 'RequestPanel',
      location: { selector: '#cm-pump-search-home', class: 'cm_req-panel_pump cm_req-panel_pump-home' },
      template: 'RequestPumpPanel',
      ...pumpSearchPanelConfig,
    },
    {
      name: 'HomeVehicleWidget_tab',
      type: 'VehicleWidget',
      location: '#cm-home-ymm',
      template: 'fitmentSearch/homeVehicleWidget',
      isAlwaysActive: true,
      columnBreakpoint: 810,
    },
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
      isAlwaysActive: true,
      isAlwaysColumnLayout: true,
    },
    {
      name: 'Garage',
      location: { selector: '#cm-garage', class: 'cm_garage__desktop cm_mobile-hide' },
      template: 'fitmentSearch/garage',
      doNotRedirectOnVehicleSelect: isProductPage,
      useVehicleWidget: true,
    },
    {
      name: 'MobileGarage',
      type: 'Garage',
      location: { selector: '#cm-mobile-garage', class: 'cm_garage__mobile cm_desktop-hide' },
      template: 'fitmentSearch/garage',
      doNotRedirectOnVehicleSelect: isProductPage,
      useVehicleWidget: true,
    },
    {
      name: 'CustomFacetDialogVehicleWidget',
      type: 'VehicleWidget',
      isAlwaysActive: true,
      isAlwaysColumnLayout: true,
    },
    {
      name: 'VerifyFitment',
      location: {
        selector: '#cm-verify-fitment',
        class: 'cm_vehicle-widget__verify-fitment-container',
      },
      template: 'fitmentSearch/verifyFitment',
      columnBreakpoint: 1000,
    },
    {
      name: 'VerifyFitmentStub',
      type: 'VerifyFitment',
      location: '#cm-verify-fitment-stub',
      template: 'fitmentSearch/verifyFitment',
    },
    {
      name: 'PumpDetails',
      type: 'ProductData',
      location: '.small-hide #cm-pump-details',
      template: 'product/productData',
      fields: ['PumpSearchProductAttributes'],
    },
    {
      name: 'PumpDetailsMobile',
      type: 'ProductData',
      location: '.medium-hide.large-up-hide #cm-pump-details',
      template: 'product/productData',
      fields: ['PumpSearchProductAttributes'],
    },
    {
      name: 'VehicleWidgetDialog',
      template: 'fitmentSearch/dialog',
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'CustomFacetDialog',
      type: 'ContextDialog',
      location: { class: 'FacetDialog' },
      template: 'CustomFacetDialog',
    },
    {
      name: 'CustomFacetDialogButton',
      type: 'DialogButton',
      dialogName: 'CustomFacetDialog',
    },
  ],
};
